<template>
  <button type="button" :class="classes">
    <slot />
  </button>
</template>

<script lang="ts">
import { computed } from "vue";
export type ButtonProps = {
    variant?: "primary" | "critical" | "success" | "outline" | "dashed" | undefined;
    small?: boolean;
};
export const useButtonClasses = (props: ButtonProps) => computed(() => {
    const classes = [
        "inline-flex",
        "items-center",
        "justify-center",
        "px-4",
        "border",
        "rounded-full",
        "gap-3",
        "whitespace-nowrap",
        "cursor-pointer",
        "disabled:opacity-50",
        "disabled:pointer-events-none",
        props.small ? "py-1" : "py-2",
    ];
    if (props.variant) {
        switch (props.variant) {
            case "primary":
                classes.push("bg-blue-600", "text-white", "border-blue-600", "hover:bg-black", "hover:border-black");
                break;
            case "critical":
                classes.push("bg-red", "text-white", "border-red");
                break;
            case "success":
                classes.push("bg-green", "text-white", "border-green");
                break;
            case "outline":
                classes.push("bg-transparent", "text-inherit", "border-current", "hover:text-blue-600");
                break;
            case "dashed":
                classes.push("bg-transparent", "text-inherit", "border-dashed", "border-current", "hover:text-blue-600");
                break;
        }
    }
    else {
        classes.push("bg-black", "text-white", "border-black", "hover:bg-blue-600", "hover:border-blue-600");
    }
    return classes;
});
//__BLOCK END__
//</script>

<script lang="ts" setup>
const props = defineProps<ButtonProps>();
const classes = useButtonClasses(props);
</script>
