<template>
  <DynamicLink class="no-underline [&[inert]]:opacity-30" :class="classes" :to="to">
    <slot />
  </DynamicLink>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router";
import { useButtonClasses, type ButtonProps } from "$app/components/Button.vue";
import { DynamicLink } from "$app/components/Link.vue";
const props = defineProps<ButtonProps & {
    to: string | RouteLocationRaw;
}>();
const classes = useButtonClasses(props);
</script>
