<template>
  <main class="flex h-full flex-col items-center justify-center gap-4 bg-black text-center text-white">
    <img :src="logo" class="size-40 invert" alt="" />
    <div class="text-3xl font-bold">{{ heading }}</div>
    <div>{{ text }}</div>
    <Link :to="{ name: 'root' }">Go home?</Link>
  </main>
</template>

<script setup lang="ts">import { computed as _computed } from 'vue';

import Link from "$app/components/Link.vue";
import logo from "$assets/images/logo-icon.svg";
const props = defineProps<{
    code: 404 | 500;
}>();
const heading = _computed(() => (props.code === 404 ? "Page not found" : "Something went wrong"));
const text = _computed(() => props.code === 404 ? "The thing you were looking for doesn't exist... Sorry!" : "Sorry about that. Please try again!");
</script>
